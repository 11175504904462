define("projektitekt/templates/bingo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0xN1YH4+",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,0,0,[27,[26,0,\"CallHead\"],[]],[\"Bingo\"],[[\"_deprecate\"],[\"/tmp/broccoli-2043UxcGDIsxvAsO/out-267-colocated_template_processor/projektitekt/templates/bingo.hbs\"]]]],[1,1,0,0,\"\\n\\n\"],[9,\"h1\",true],[12,\"class\",\"title\",null],[10],[1,1,0,0,\"\\n  Home Office Bingo\\n  \"],[9,\"small\",true],[13,\"class\",[32,[[31,0,0,[27,[26,1,\"CallHead\"],[]],[\"subtitle\"],[[\"from\"],[\"projektitekt/styles/bingo\"]]]]],null],[10],[1,1,0,0,\"für Video-Konferenzen\"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[13,\"class\",[32,[[31,0,0,[27,[26,1,\"CallHead\"],[]],[\"content\"],[[\"from\"],[\"projektitekt/styles/bingo\"]]]]],null],[10],[1,1,0,0,\"\\n  \"],[7,\"bingo-grid\",[],[[],[]],null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"local-class\"]}",
    "meta": {
      "moduleName": "projektitekt/templates/bingo.hbs"
    }
  });

  _exports.default = _default;
});